export default {
  profile: "profile",
  notifications: "notifications",
  fields: "fields",
  wells: "wells",
  waterRights: "waterRights",
  restrictions: "restrictions",
  groupings: "groupings",
  companies: "companies",
  stocks: "stocks",
  placesOfUse: "placesOfUse",
  list: "list",
  lookup: "lookup",
  companiesLookup: "companiesLookup",
  fieldsLookup: "fieldsLookup",
  restrictionsLookup: "restrictionsLookup",
  wellsLookup: "wellsLookup",
  waterRightsLookup: "waterRightsLookup",
  dashboardCounts: "dashboardCounts",
  unreadNotificationCount: "unreadNotificationCount",
  calculation: "calculation",
  identity: "identity",
  waterRightLastReading: "waterRightLastReading",
  waterRightTags: "waterRightTags",
  restrictionDashbord: "restrictionDashbord",
  companyConfig: "companyConfig",
  waterRightLastReadingInfinite: "waterRightLastReadingInfinite",
  restrictionDashbordInfinite: "restrictionDashbordInfinite",
  wellDashboard: "wellDashboard",
  wellMapDashboard: "wellMapDashboard",
  oustandingInvites: "oustandingInvites",
  actionsCounter: "actionsCounter",
  details: "details",
  wellsForDashboardMap: "wellsForDashboardMap",
  waterRightQuantityPerCompany: "waterRightQuantityPerCompany",
  companyTotalAllocation: "companyTotalAllocation",
  companyAverageInchesPerAcre: "companyAverageInchesPerAcre",
  companyTotalAllocationByAcres: "companyTotalAllocationByAcres",
  waterAllocationPerPermitType: "waterAllocationPerPermitType",
  waterAllocationPerPriorityType: "waterAllocationPerPriorityType",
  cropAllocationByAcres: "cropAllocationByAcres",
  companyMonthlyUsage: "companyMonthlyUsage",
  outstandingCompanyCounts: "outstandingCompanyCounts",
  billingEvents: "billingEvents",
  billingUsers: "billingUsers",
  events: "events",
  financeDocuments: "financeDocuments",
  view: "view",
  analytics: "analytics",
  usage: "usage",
  reports: "reports",
  waterRightUsagePerMonth: "waterRightUsagePerMonth",
  stockUsageData: "stockUsageData",
  waterRightTotalUsageReport: "waterRightTotalUsageReport",
  companyUsers: "companyUsers",
  dwr: "dwr",
  waterRightInfoByFileNumber: "waterRightInfoByFileNumber",
  waterRightInfoByPdiv: "waterRightInfoByPdiv",
  waterRightInfoByWaterRightId: "waterRightInfoByWaterRightId",
  authorizedQuantitiesByWaterRightId: "authorizedQuantitiesByWaterRightId",
  authorizedRatesByWaterRightId: "authorizedRatesByWaterRightId",
  waterUseDataByWaterRightId: "waterUseDataByWaterRightId",
  waterUseDataByPdiv: "waterUseDataByPdiv",
  waterUseDataByGmd: "waterUseDataByGmd",
  waterUseDataByCountyCode: "waterUseDataByCountyCode",
  placeOfUseDataByWaterRightId: "placeOfUseDataByWaterRightId",
  placeOfUseDataByLegal: "placeOfUseDataByLegal",
  authorizedAcresByWaterRightId: "authorizedAcresByWaterRightId",
  authorizedAcresByLegal: "authorizedAcresByLegal",
  aquiferByPdiv: "aquiferByPdiv",
  aquiferByAquiferCode: "aquiferByAquiferCode",
  waterRightSummary: "waterRightSummary",
  waterRightAuthorizedQuantitiesSummary: "waterRightAuthorizedQuantitiesSummary",
} as const;
