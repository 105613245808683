import useCompanyLookups from "@/queries/useCompanyLookups";
import { useAppDispatch } from "@/stores";
import { saveSelectedCompanyId } from "@/stores/company.store";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Card, Modal, Select, Typography } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";

export enum EnitityName {
  User = "User",
  Field = "Field",
  Well = "Well",
  WaterRight = "Water Right",
  Grouping = "Grouping",
  Stock = "Stock",
  Restriction = "Restriction",
}

export type AddSelectCompanyModalRef = {
  open: () => void;
  isOpen: boolean;
};

type AddSelectCompanyModalProps = {
  entityName: EnitityName;
  onSubmit?: (selectedCompanyId: string) => void;
};

const AddSelectCompanyModal = ({ entityName, onSubmit }: AddSelectCompanyModalProps, ref: React.Ref<AddSelectCompanyModalRef>) => {
  const dispatch = useAppDispatch();
  const { companies, isLoading } = useCompanyLookups(undefined, true);
  const [selected, setSelected] = useState<string>();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchToCompany = (companyId: string) => {
    dispatch(saveSelectedCompanyId(companyId));
  };

  const handleSubmit = () => {
    if (!selected) return;

    handleSwitchToCompany(selected);
    handleClose();
    onSubmit?.(selected);
  };

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    isOpen: open,
  }));

  return (
    <Modal
      maskClosable={true}
      open={open}
      okText="Switch To"
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !!!selected,
        loading: isLoading,
        icon: <ThunderboltOutlined />,
      }}
      cancelText="Cancel"
      onCancel={handleClose}
      cancelButtonProps={{ icon: <CloseOutlined /> }}
      width={700}
      closable={false}
    >
      <Card loading={isLoading} title="Switch to Company" className="removeBoxShadow removeMargin">
        <Typography.Paragraph>Please select a company to add a {entityName} to</Typography.Paragraph>
        <Select
          disabled={isLoading}
          loading={isLoading}
          style={{ width: "100%" }}
          showSearch
          value={selected}
          onSelect={setSelected}
          optionFilterProp="value"
          filterOption={(input, option) => option?.children?.toString().toLowerCase().includes(input.toLowerCase()) ?? false}
          placeholder="Select a company"
        >
          {companies?.map((company: any) => (
            <Select.Option key={company.id} value={company.id}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </Card>
    </Modal>
  );
};

export default forwardRef(AddSelectCompanyModal);
