import { post } from "@/services/request";

export const getWaterRightInfoByFileNumber = (request: { fileNumber: string | undefined; vestedCounty?: string; waterRightQualifier?: string }) => {
  return post(`aggregator/dwr/waterrightinfo/filenumber`, request);
};

export const getWaterRightInfoByPdiv = (request: { pdiv: number }) => {
  return post(`aggregator/dwr/waterrightinfo/pdiv`, request);
};

export const getWaterRightInfoByWaterRightId = (request: { waterRightId: number }) => {
  return post(`aggregator/dwr/waterrightinfo/wrid`, request);
};

export const getAuthorizedQuantitiesByWaterRightId = (request: { waterRightId: number }) => {
  return post(`aggregator/dwr/authquantities/wrid`, request);
};

export const getAuthorizedRatesByWaterRightId = (request: { waterRightId: number }) => {
  return post(`aggregator/dwr/authrates/wrid`, request);
};

export const getWaterUseDataByWaterRightId = (request: { waterRightId: number; startYear: number; endYear: number }) => {
  return post(`aggregator/dwr/wateruse/wrid`, request);
};

export const getWaterUseDataByPdiv = (request: { pdiv: number; startYear: number; endYear: number }) => {
  return post(`aggregator/dwr/wateruse/pdiv`, request);
};

export const getWaterUseDataByGmd = (request: { gmd: number; startYear: number; endYear: number }) => {
  return post(`aggregator/dwr/wateruse/gmd`, request);
};

export const getWaterUseDataByCountyCode = (request: { countyCode: string; startYear: number; endYear: number }) => {
  return post(`aggregator/dwr/wateruse/countycode`, request);
};

export const getPlaceOfUseDataByWaterRightId = (request: { waterRightId: number }) => {
  return post(`aggregator/dwr/placeofuse/wrid`, request);
};

export const getPlaceOfUseDataByLegal = (request: { section: number; township: number; townshipDirection: string; range: number; rangeDirection: string }) => {
  return post(`aggregator/dwr/placeofuse/legal`, request);
};

export const getAuthorizedAcresByWaterRightId = (request: { waterRightId: number }) => {
  return post(`aggregator/dwr/authacres/wrid`, request);
};

export const getAuthorizedAcresByLegal = (request: { section: number; township: number; townshipDirection: string; range: number; rangeDirection: string }) => {
  return post(`aggregator/dwr/authacres/legal`, request);
};

export const getAquiferByPdiv = (request: { pdiv: number }) => {
  return post(`aggregator/dwr/aquifer/pdiv`, request);
};

export const getAquiferByAquiferCode = (request: { aquiferCode: number }) => {
  return post(`aggregator/dwr/authacres/aquifercode`, request);
};
