import { getWaterRightInfoByPdiv } from "@/apis/dwr.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type DWRGetWaterRightByPdivQueryProps = {
  pdiv: number;
};

export default function useDWRGetWaterRightByPdivQuery(props: DWRGetWaterRightByPdivQueryProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched,
    data: dwrWaterRightInfoByPdiv,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.dwr,
      CacheKeys.waterRightInfoByFileNumber,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getWaterRightInfoByPdiv({ pdiv: props.pdiv });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch dwr water right info by pdiv");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 120 * 1000 * 1000, // 2 hours
  });

  const invalidateDWRWaterRightInfoByPdiv = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.dwr,
        CacheKeys.waterRightInfoByPdiv,
        {
          pdiv: props.pdiv,
        },
      ],
    });
  }, [queryClient, props.pdiv]);

  return {
    isFetched,
    dwrWaterRightInfoByPdiv,
    invalidateDWRWaterRightInfoByPdiv,
    isLoading,
  };
}
