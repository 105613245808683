import { getAuthorizedAcresByWaterRightId } from "@/apis/dwr.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type DWRGetAuthorizedAcresByWaterRightIdProps = {
  waterRightId: number;
};

export default function useDWRGetAuthorizedAcresByWaterRightIdQuery(props: DWRGetAuthorizedAcresByWaterRightIdProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched,
    data: dwrAuthorizedAcresByWaterRightId,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.dwr,
      CacheKeys.authorizedAcresByWaterRightId,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getAuthorizedAcresByWaterRightId({
        waterRightId: props.waterRightId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch dwr auth acres by water right id");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 120 * 1000 * 1000, // 2 hours
  });

  const invalidateDWRWAuthorizedAcresByWaterRightId = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.dwr,
        CacheKeys.authorizedAcresByWaterRightId,
        {
          waterRightId: props.waterRightId,
        },
      ],
    });
  }, [queryClient, props.waterRightId]);

  return {
    isFetched,
    dwrAuthorizedAcresByWaterRightId,
    invalidateDWRWAuthorizedAcresByWaterRightId,
    isLoading,
  };
}
