import useDWRGetPlaceOfUseDataByWaterRightId from "@/queries/useDWRGetPlaceOfUseDataByWaterRightId";
import { Card, Descriptions } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  waterRightId: number;
  pdiv: number;
}

const PlaceOfUseTab: FC<Props> = (props) => {
  const { pdiv, waterRightId } = props;

  const [placeData, setPlaceData] = useState<any>(undefined);

  const {
    dwrPlaceOfUseDataByWaterRightId: placeOfUseData,
    invalidateDWRWPlaceOfUseDataByWaterRightId,
    isFetched,
    isLoading,
  } = useDWRGetPlaceOfUseDataByWaterRightId({
    waterRightId,
  });

  useEffect(() => {
    if (placeOfUseData && placeOfUseData.length > 0) {
      const placeOfUseDataForWaterRight = placeOfUseData?.find((wrData: any) => wrData?.waterRightId === waterRightId);
      setPlaceData(placeOfUseDataForWaterRight);
    }
    // eslint-disable-next-line
  }, [placeOfUseData]);

  return (
    <Card loading={isLoading}>
      <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 200 }} style={{ width: 400, height: "100%" }}>
        <Descriptions.Item label="Total Acres Irrigated">{placeData?.totalAcresIrrigated ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Total New Acres Irrigated Quantity">{placeData?.totalNewAcresIrrigated ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Place Of Use Comment">{placeData?.placeOfUseComment ?? "N/A"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default PlaceOfUseTab;
