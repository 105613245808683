import LookupLabel from "@/components/LookupLabel/LookupLabel";
import useDWRGetWaterUseDataByPdivQuery from "@/queries/useDWRGetWaterUseDataByPdivQuery";
import { Card, DatePicker, Divider, Empty, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";

interface Props {
  pdiv: number;
}

const { RangePicker } = DatePicker;

const WaterUseDataTab: FC<Props> = (props) => {
  const { pdiv } = props;

  const [columns, setColumns] = useState<any[]>([]);
  const [years, setYears] = useState<[Dayjs | null, Dayjs | null]>([dayjs("2020-01-01"), dayjs(`${dayjs().year() - 1}-12-31`)]);

  const {
    dwrWaterUseDataByPdiv: waterUseData,
    invalidateDWRWaterUseDataByPdiv,
    isFetched,
    isLoading,
  } = useDWRGetWaterUseDataByPdivQuery({
    pdiv,
    startYear: years[0]?.year() ?? 2020,
    endYear: years[1]?.year() ?? 2024,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [waterUseData]);

  // const renderExpandData = (record: any) => {
  //   return (
  //     <>
  //       <Title level={5}>Water Use Report</Title>
  //       <Divider style={{ padding: 0, marginTop: 2, marginBottom: 2 }} />
  //       <div style={{ padding: 5 }}>
  //         <div style={{ display: "flex", flexDirection: "row" }}>
  //           <div
  //             style={{
  //               fontSize: 12,
  //               width: 120,
  //               fontWeight: "bold",
  //               padding: 0,
  //             }}
  //           >
  //             Report Code:{" "}
  //           </div>
  //           <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.waterUseReportCode ?? "N/A"}</div>
  //         </div>
  //         <div style={{ display: "flex", flexDirection: "row" }}>
  //           <div
  //             style={{
  //               fontSize: 12,
  //               width: 120,
  //               fontWeight: "bold",
  //               padding: 0,
  //             }}
  //           >
  //             Report Comment:{" "}
  //           </div>
  //           <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.waterUseReportComment ?? "N/A"}</div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const calculateColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Year",
        key: "waterUseYear",
        dataIndex: "waterUseYear",
        width: 50,
        render: (val: any, record: any) => val,
      },
      {
        title: "Beginning Meter Reading",
        key: "beginningMeterReading",
        dataIndex: "beginningMeterReading",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Ending Meter Reading",
        key: "endMeterReading",
        dataIndex: "endMeterReading",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Metered Quantity",
        key: "meteredQuantity",
        dataIndex: "meteredQuantity",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Meter Units",
        key: "internalMeterUnits",
        dataIndex: "internalMeterUnits",
        render: (val: any, record: any) => <LookupLabel lookupType={"meterUnits"} value={val} />,
      },
      {
        title: "Acre Feet Pumped",
        key: "acreFeetPumped",
        dataIndex: "acreFeetPumped",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Crop Type",
        key: "cropType",
        dataIndex: "cropType",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Energy Type",
        key: "energyType",
        dataIndex: "energyType",
        render: (val: any, record: any) => val ?? "N/A",
      },
    ];

    setColumns(tempColumns);
  };

  return (
    <Card loading={isLoading}>
      <RangePicker
        value={years}
        onChange={(dates) => setYears(dates as [Dayjs | null, Dayjs | null])}
        style={{ marginBottom: 5, marginTop: 0 }}
        allowClear={false}
        picker="year"
        disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2020-01-01")}
      />
      <br />
      <Table
        style={{ height: 400 }}
        rowKey={(row: any) => row.pdivId}
        loading={isLoading || !isFetched}
        columns={columns}
        dataSource={waterUseData || []}
        size="small"
        pagination={false}
        // expandable={{
        //   showExpandColumn: false,
        //   defaultExpandAllRows: true,
        //   expandedRowRender: (record: any) => renderExpandData(record),
        // }}
        className="customScrollBarStyle"
        scroll={{ y: 350 }}
        locale={{
          emptyText: <Empty description="No Water Use Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
      />
    </Card>
  );
};

export default WaterUseDataTab;
