import { getWaterRightInfoByFileNumber } from "@/apis/dwr.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type DWRGetWaterRightByFileNumberQueryProps = {
  fileNumber: string | undefined;
  vestedCounty?: string;
  waterRightQualifier?: string;
};

export default function useDWRGetWaterRightByFileNumberQuery(props: DWRGetWaterRightByFileNumberQueryProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched: dwrWaterRightInfoByFileNumberFetched,
    data: dwrWaterRightInfoByFileNumber,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.dwr,
      CacheKeys.waterRightInfoByFileNumber,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getWaterRightInfoByFileNumber({
        fileNumber: props.fileNumber,
        vestedCounty: props.vestedCounty,
        waterRightQualifier: props.waterRightQualifier,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch dwr water right info by file number");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id && !!props.fileNumber,
    gcTime: 120 * 1000 * 1000, // 2 hours
  });

  const invalidateDWRWaterRightInfoByFileNumber = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.dwr,
        CacheKeys.waterRightInfoByFileNumber,
        {
          fileNumber: props.fileNumber,
          vestedCounty: props.vestedCounty,
          waterRightQualifier: props.waterRightQualifier,
        },
      ],
    });
  }, [queryClient, props.fileNumber, props.vestedCounty, props.waterRightQualifier]);

  return {
    dwrWaterRightInfoByFileNumberFetched,
    dwrWaterRightInfoByFileNumber,
    invalidateDWRWaterRightInfoByFileNumber,
    isLoading,
  };
}
