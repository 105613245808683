import { MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, Divider, Drawer, Form, Input, InputNumber, Modal, Typography, Upload, message } from "antd";
import { sendSupportEmail } from "@/apis/communication.api";
import { FC, useEffect, useState } from "react";
import { MdAdjust } from "react-icons/md";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";

const locations = [
  {
    name: "GMD 1 - Western Kansas",
    address: "906 W 5th st, Scott City, KS 67871",
    phone: "(620) 872-5563",
    latitude: undefined,
    longitude: undefined,
  },
  {
    name: "GMD 3 - South West Kansas",
    address: "2009 E Spruce St, Garden City, KS 67846",
    phone: "(620) 275-7147",
    latitude: undefined,
    longitude: undefined,
  },
  {
    name: "GMD 4 - Northwest Kansas",
    address: "1290 W 4th St, Colby, KS 67701",
    phone: "(785) 462-3915",
    latitude: undefined,
    longitude: undefined,
  },
  {
    name: "GMD 5 - Big Bend",
    address: "125 S Main St, Stafford, KS 67578",
    phone: "(620) 234-5352",
    latitude: undefined,
    longitude: undefined,
  },
  {
    name: "Kansas Department of Agriculture, Division of Water Resources",
    address: "4532 W. Jones Ave, Suite B Garden City, KS 67846",
    phone: "(620) 276-2901  or  (620) 765-7110",
    latitude: undefined,
    longitude: undefined,
  },
];

interface Props {
  mobile?: boolean;
  onSuccess?: (ref: any) => void;
  open?: boolean;
  onCancel?: () => void;
}

const OrderTokensDrawer: FC<Props> = (props) => {
  const { onSuccess, open, onCancel, mobile } = props;

  const [form] = Form.useForm();

  const user = useAuth();

  const { profile } = useSelector((state: any) => state.user);

  const [fileList, setFileList] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        userId: profile.userId,
        billingAddress: [profile.addressLine1, profile.addressLine2, profile.city, profile.state, profile.country, profile.zipCode].filter((x) => x).join(", "),
      });
    }
  }, [profile]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const submitQuery = {
          userEmail: values.email,
          attachments: fileList.map((file) => file?.response?.value) ?? null,
          subject: values.subject,
          body: `Token Order Request 
        
                    First Name: ${values.firstName}
                    Last Name: ${values.lastName}
                    Email: ${values.email}
                    Phone Number: ${values.phoneNumber}
                    
                    Number of Tokens: ${values.numberOfTokens}
                    
                    Billing Address: ${values.billingAddress}
        
                    Shipping Address: ${values.shippingAddress}
                    `,
          phoneNumber: values.phoneNumber ?? null,
        };

        handleSupportQuerySubmitData(submitQuery);
      })
      .catch((errors) => {
        message.error("Support query failed to send");
        setLoading(false);
      });
  };

  const handleSupportQuerySubmitData = async (data: any) => {
    const response = await sendSupportEmail(data);

    setLoading(false);
    if (response.ok) {
      if (mobile) {
        //TODO
      } else {
        if (response.ok) {
          const data = await response.json();
          if (data.isSuccess) {
            onSuccess && onSuccess(data.value);
          } else {
            message.error("Support query failed to send");
          }
        }
      }
    } else {
      message.error("Support query failed to send");
    }
  };

  const renderContent = () => {
    return (
      <>
        <Divider orientation="left">Pick Up Locations</Divider>
        <Collapse>
          {locations.map((location: any, index: number) => (
            <Collapse.Panel header={location.name} key={index}>
              <b>Address: </b>
              {location.address}
              <br />
              <b>Phone: </b>
              {location.phone}
              <br />
            </Collapse.Panel>
          ))}
        </Collapse>
        <Divider orientation="left">Order Online</Divider>
        <Form id="supportQueryModal" form={form} layout="vertical">
          <Form.Item initialValue={user?.user?.profile?.givenName} name="firstName" label="First Name">
            <Input prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>

          <Form.Item name="lastName" label="Last Name">
            <Input prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid e-mail",
              },
              {
                required: true,
                message: "Please input your e-mail",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item name="phoneNumber" label="Phone Number">
            <Input prefix={<PhoneOutlined />} placeholder="Phone Number (Optional)" />
          </Form.Item>

          <Form.Item
            name="numberOfTokens"
            label="Number of Tokens"
            rules={[
              {
                required: true,
                message: "Please enter the number of tokens required",
              },
            ]}
          >
            <InputNumber prefix={<MdAdjust />} min={0} placeholder="Enter the number of tokens required" style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="billingAddress"
            label="Billing Address"
            rules={[
              {
                required: true,
                message: "Please enter your billing address",
              },
            ]}
          >
            <Input.TextArea rows={6} placeholder="Please enter the address you would like your tokens shipped to" />
          </Form.Item>

          <Form.Item
            name="shippingAddress"
            label="Shipping Address"
            rules={[
              {
                required: true,
                message: "Please enter the address you would like your tokens shipped to",
              },
            ]}
          >
            <Input.TextArea rows={6} placeholder="Enter the address you would like your tokens shipped to" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" style={{ width: "100%" }} onClick={handleSubmit} disabled={loading} loading={loading}>
              Submit Order
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} onClick={onCancel} disabled={loading} loading={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <Drawer title="Order Tokens" open={open} placement="right" width={500} onClose={onCancel} bodyStyle={{ paddingTop: 5 }} closable={false} maskClosable={false}>
      <p>Review pick up locations or complete the online order form.</p>
      {renderContent()}
    </Drawer>
  );
};

export default OrderTokensDrawer;
