import { RegistrationModal } from "@/components";
import PageFooter from "@/components/PageComponents/PageFooter/PageFooter";
import { constants, routes } from "@/configs";
import useCustomNavigate from "@/services/useCustomNavigate";
import useWindowSize from "@/services/useWindowSize";
import { Button, Card, Col, List, notification, Row, Space } from "antd";
import { FC, useState } from "react";

const section2data = [
  {
    title: "IRRIGATION",
    image: "images/irrigation.png",
    description: ["Compatible with all water meters,", "sharable water usage data"],
  },
  {
    title: "LIVESTOCK",
    image: "images/livestock.png",
    description: ["Monthly report generation,", "dual use, deduction wells, and", "lagoon tracking"],
  },
  {
    title: "LAND MANAGERS",
    image: "images/land_managers.png",
    description: ["Risk management,", "asset protection"],
  },
  {
    title: "IRRIGATION DISTRICTS",
    image: "images/irrigation_districts.png",
    description: ["Global management of allocations,", "transferable water rights ownership"],
  },
  {
    title: "MUNICIPALITIES",
    image: "images/municipalities.png",
    description: ["Affordable software update,", "scalable to fit your needs"],
  },
  {
    title: "CORPORATE SUSTAINABILITY",
    image: "images/corporate_sustainability.png",
    description: ["Water intensity metrics,", "third-party verified data"],
  },
];

const section4_data = [
  {
    title: "RISK MANAGEMENT",
    description: ["Organize water rights,", "restrictions, overlaps", "and more to mitigate", "over-pumping"],
  },
  {
    title: "OPERATIONS",
    description: ["Make more informed in-", "season water", "management decisions", "to maximize yields"],
  },
  {
    title: "CONSERVATION",
    description: ["Establish the water use", "best practices that", "benefit the generations", "to come"],
  },
];

const Home: FC = () => {
  const size = useWindowSize();
  const { navigate, setCurrent } = useCustomNavigate();

  const [registrationModalState, setRegistrationModalState] = useState<any>({
    data: undefined,
    open: false,
  });

  const [api, contextHolder] = notification.useNotification();

  const handleNav = (route: any) => {
    setCurrent?.(route.path);
    navigate(route);
  };

  const handleRegistrationCancel = () => {
    setRegistrationModalState({
      ...registrationModalState,
      open: false,
      data: undefined,
    });
  };

  const openRegistrationCompleteNotification = () => {
    handleRegistrationCancel();

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Signup success",
      description: "A confirmation email has been sent to your inbox, please follow the instructions in the email to confirm your account.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  return (
    <div id="home">
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 94px)",
          position: "relative",
          overflow: "hidden",
          fontFamily: "Raleway",
          lineHeight: 1.1,
          fontSize: "5.9vw",
          color: "white",
        }}
      >
        {size.width <= 1280 && (
          <div style={{ position: "absolute", top: 20, left: "calc(50% - 75px)", zIndex: 4 }}>
            <Button
              id="navRegisterBtn"
              type="primary"
              onClick={() =>
                setRegistrationModalState({
                  ...registrationModalState,
                  open: true,
                })
              }
              style={{ width: 150, fontSize: 18, fontWeight: 500, height: 42 }}
            >
              Register
            </Button>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.35)",
            zIndex: 1,
          }}
        />
        <video autoPlay muted loop playsInline src={"videos/background_video.mp4"} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            padding: 20,
          }}
        >
          <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>PROTECTING OUR</div>
          <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>MOST VALUABLE</div>
          <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>
            RES
            <img src="images/text_droplet_white.png" alt="droplot" style={{ height: "0.78em", width: "0.78em", letterSpacing: 1.2 }} />
            URCE
          </div>
          <div style={{ fontSize: "clamp(12px, 1.8vw, 16px)", marginTop: 15, letterSpacing: 1.2 }}>WATER MANAGEMENT SIMPLIFIED</div>
          <div style={{ fontSize: "clamp(12px, 1.8vw, 16px)", letterSpacing: 1.2 }}>THROUGH SOFTWARE</div>
          <Button
            type="text"
            size="large"
            onClick={() => handleNav(routes.contactUs)}
            style={{ marginTop: 20, paddingLeft: 55, paddingRight: 55, border: "white 1px solid", borderRadius: 0, color: "white", fontWeight: "bold" }}
          >
            LEARN HOW
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "max(calc(100vh - 64px), 100%)",
          position: "relative",
          overflow: "hidden",
          fontFamily: "Raleway",
          lineHeight: 1.1,
          color: "white",
          backgroundImage: "url(images/section2_background.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          paddingBottom: 50,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            fontSize: "clamp(14px, 2vw, 26px)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            letterSpacing: 1.2,
            fontWeight: "bold",
            color: "white",
          }}
        >
          A SOFTWARE SOLUTION FOR{" "}
          <div
            style={{ fontFamily: "Playwrite US Trad", paddingLeft: 15, paddingRight: 8, marginTop: -12, paddingBottom: 12, paddingTop: 12, fontSize: "clamp(24px, 2vw, 34px)", fontWeight: "normal" }}
          >
            everyone
          </div>{" "}
          WHO MANAGES WATER
        </div>
        <List
          grid={{
            gutter: 10,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={section2data}
          renderItem={(item) => (
            <List.Item style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flexWrap: "wrap", textAlign: "center" }}>
              <img src={item.image} alt="cardImage" style={{ width: "200px", height: "200px" }} />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "wrap", fontWeight: "bold", color: "white" }}>{item.title}</div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white", lineHeight: 1.2 }}>
                {item.description.map((desc) => (
                  <div>
                    {desc}
                    <br />
                  </div>
                ))}
              </div>
            </List.Item>
          )}
          style={{ maxWidth: 800, margin: "auto", padding: 20 }}
        />
        <div
          style={{
            fontSize: "clamp(14px, 2vw, 26px)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            letterSpacing: 1.2,
            fontWeight: "bold",
            color: "white",
          }}
        >
          SAVE TIME, MONEY, AND{" "}
          <span
            style={{ fontFamily: "Playwrite US Trad", paddingLeft: 10, paddingRight: 8, marginTop: -12, paddingBottom: 12, paddingTop: 12, fontSize: "clamp(24px, 2vw, 34px)", fontWeight: "normal" }}
          >
            water
          </span>{" "}
          THROUGH AUTOMATION
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "max(calc(100vh - 64px), 100%)",
          position: "relative",
          overflow: "hidden",
          fontFamily: "Raleway",
          lineHeight: 1.1,
          color: constants.primaryColor,
          backgroundColor: "white",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
        }}
      >
        <div style={{ fontSize: "clamp(18px, 6vw, 50px)", letterSpacing: 1.2, textAlign: "center" }}>COMPREHENSIVE WATER MANAGEMENT</div>
        <div style={{ fontSize: "clamp(18px, 6vw, 50px)", letterSpacing: 1.2, textAlign: "center" }}>DEVELOPED BY FARMERS</div>
        <div style={{ fontSize: "clamp(18px, 6vw, 50px)", letterSpacing: 1.2, textAlign: "center" }}>FOR FARMERS</div>
        {/* <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>WE'RE FARMERS</div>
        <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>WHO FIGURED OUT A</div>
        <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>
          S<img src="images/text_droplet_blue.png" alt="droplot" style={{ height: "0.78em", width: "0.78em", letterSpacing: 1.2 }} />
          LUTION.
        </div> */}
        <img src="images/vandwater_droplet_color_opacity35.png" alt="logo" style={{ position: "absolute", width: "50%", maxWidth: 420, maxHeight: 420, top: 0, left: "calc(70vw)", zIndex: 0 }} />
        <Button
          type="text"
          size="large"
          onClick={() => handleNav(routes.aboutUs)}
          style={{
            marginTop: 20,
            paddingLeft: 55,
            paddingRight: 55,
            border: "white 1px solid",
            borderColor: "#0D9B1A",
            borderRadius: 0,
            color: "#0D9B1A",
            fontWeight: "bold",
          }}
        >
          OUR STORY
        </Button>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "flex-start", flexWrap: "wrap", paddingTop: 30 }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <img src="images/section4_image_2025_01_06_02.png" alt="" style={{ height: 400, width: 400, objectPosition: "center", objectFit: "fill", overflow: "hidden", zIndex: 1 }} />
          </div>
          <div style={{ flex: 2, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                height: "clamp(100px, 25vw, 200px)",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: size.width < 785 ? "center" : "flex-start",
                padding: "clamp(5px, 1vw, 60px)",
              }}
            >
              <div
                style={{
                  marginLeft: size.width > 1400 ? 200 : size.width > 1200 ? 100 : 0,
                  fontSize: "clamp(20px, 4.5vw, 46px)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  letterSpacing: 1.2,
                  fontWeight: "bold",
                }}
              >
                small investment
              </div>
              <div
                style={{
                  marginLeft: size.width > 1400 ? 200 : size.width > 1200 ? 100 : 0,
                  fontSize: "clamp(32px, 7vw, 70px)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  letterSpacing: 1.2,
                  fontWeight: "bold",
                }}
              >
                BIG IMPACT
              </div>
            </div>
            <div
              style={{
                height: 200,
                width: "100%",
                background: "radial-gradient(circle, rgba(2,3,160,1) 0%, rgba(2,3,120,1) 100%)",
                backgroundColor: constants.primaryColor,
                display: "flex",
                flexDirection: "row",
                gap: "clamp(10px, 2vw, 30px)",
                padding: "clamp(5px, 1vw, 60px)",
              }}
            >
              <div style={{ marginLeft: size.width > 1400 ? 200 : size.width > 1200 ? 100 : 0 }}>
                <div style={{ textAlign: "left", width: "100%", fontWeight: "bold", color: "white" }}>{section4_data[0].title}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    color: "white",
                    lineHeight: 1.2,
                    paddingTop: 10,
                    fontSize: "clamp(12px, 2vw, 16px)",
                  }}
                >
                  {section4_data[0].description.map((desc) => (
                    <div>
                      {desc}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: 1, height: 80, marginTop: 10, marginBottom: 10, backgroundColor: "white" }} />
              <div>
                <div style={{ textAlign: "left", width: "100%", fontWeight: "bold", color: "white" }}>{section4_data[1].title}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    color: "white",
                    lineHeight: 1.2,
                    paddingTop: 10,
                    fontSize: "clamp(12px, 2vw, 16px)",
                  }}
                >
                  {section4_data[1].description.map((desc) => (
                    <div>
                      {desc}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: 1, height: 80, marginTop: 10, marginBottom: 10, backgroundColor: "white" }} />
              <div>
                <div style={{ textAlign: "left", width: "100%", fontWeight: "bold", color: "white" }}>{section4_data[2].title}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    color: "white",
                    lineHeight: 1.2,
                    paddingTop: 10,
                    fontSize: "clamp(12px, 2vw, 16px)",
                  }}
                >
                  {section4_data[2].description.map((desc) => (
                    <div>
                      {desc}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          minHeight: 450,
          position: "relative",
          overflow: "hidden",
          fontFamily: "Raleway",
          lineHeight: 1.1,
          color: "white",
          backgroundImage: "url(images/section5_background_2025_01_06.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "50% 42%",
          backgroundRepeat: "no-repeat",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.35)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            padding: 20,
          }}
          onClick={() => handleNav(routes.contactUs)}
        >
          <div>
            <img src="images/vandwater_droplet_white.png" alt="" style={{ width: "clamp(100px, 20vw, 150px)", height: "clamp(100px, 20vw, 150px)", zIndex: 1 }} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", flexWrap: "wrap", letterSpacing: 1.2, marginLeft: -20 }}>
            <div style={{ fontSize: "clamp(26px, 7vw, 52px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 1.2 }}>CONTACT US TO</div>
            <div style={{ fontSize: "clamp(26px, 7vw, 56px)", display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", letterSpacing: 3, fontWeight: "bold" }}>GET STARTED</div>
          </div>
        </div>
      </div>
      {size.width <= 1280 && registrationModalState.open && (
        <RegistrationModal onSuccess={openRegistrationCompleteNotification} open={registrationModalState.open} onCancel={handleRegistrationCancel} />
      )}
      <div style={{ padding: 10, backgroundColor: "white" }} />

      <PageFooter />
    </div>
  );
};

export default Home;
