import { getWaterUseDataByPdiv } from "@/apis/dwr.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type DWRGetWaterUseDataByPdiv = {
  pdiv: number;
  startYear: number;
  endYear: number;
};

export default function useDWRGetWaterUseDataByPdivQuery(props: DWRGetWaterUseDataByPdiv) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched,
    data: dwrWaterUseDataByPdiv,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.dwr,
      CacheKeys.waterUseDataByPdiv,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getWaterUseDataByPdiv({
        pdiv: props.pdiv,
        startYear: props.startYear,
        endYear: props.endYear,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch dwr water use data by pdiv");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 120 * 1000 * 1000, // 2 hours
  });

  const invalidateDWRWaterUseDataByPdiv = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.dwr,
        CacheKeys.waterUseDataByPdiv,
        {
          pdiv: props.pdiv,
          startYear: props.startYear,
          endYear: props.endYear,
        },
      ],
    });
  }, [queryClient, props.pdiv, props.startYear, props.endYear]);

  return {
    isFetched,
    dwrWaterUseDataByPdiv,
    invalidateDWRWaterUseDataByPdiv,
    isLoading,
  };
}
