import { Card, Descriptions } from "antd";
import { FC, useEffect } from "react";
import dayjs from "dayjs";
import { constants } from "@/configs";

interface Props {
  waterRightData: any;
}

const DatesTab: FC<Props> = (props) => {
  const { waterRightData } = props;

  useEffect(() => {
    // console.log("water right data => ", waterRightData);
  }, [waterRightData]);

  return (
    <Card>
      <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 200 }} style={{ width: 400, height: "100%" }}>
        <Descriptions.Item label="Approval">{waterRightData?.approvalDate ? dayjs(waterRightData?.approvalDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Perfect By">{waterRightData?.currentPerfectByDate ? dayjs(waterRightData?.currentPerfectByDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Certificate Issued">{waterRightData?.certificateIssuedDate ? dayjs(waterRightData?.certificateIssuedDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Complete By">{waterRightData?.currentCompleteByDate ? dayjs(waterRightData?.currentCompleteByDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Priority Date">{waterRightData?.priorityDate ? dayjs(waterRightData?.priorityDate).format(constants.dateFormat) : "N/A"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default DatesTab;
