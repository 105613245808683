import useDWRGetAuthorizedRatesByWaterRightIdQuery from "@/queries/useDWRGetAuthorizedRatesByWaterRightIdQuery";
import { Card, Descriptions } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  waterRightId: number;
  pdiv: number;
}

const AuthorizedRatesTab: FC<Props> = (props) => {
  const { waterRightId, pdiv } = props;

  const [authRateData, setAuthRateData] = useState<any>(undefined);

  const { dwrAuthorizedRatesByWaterRightId: authorizedRates, invalidateDWRWAuthorizedRatesByWaterRightId, isFetched, isLoading } = useDWRGetAuthorizedRatesByWaterRightIdQuery({ waterRightId });

  useEffect(() => {
    // console.log("auth rate data => ", authorizedRates);
    if (authorizedRates && authorizedRates.length > 0) {
      const authDataForWaterRight = authorizedRates?.find((wrData: any) => wrData?.pdivId === pdiv);
      setAuthRateData(authDataForWaterRight);
    }
    // eslint-disable-next-line
  }, [authorizedRates]);

  return (
    <Card loading={isLoading}>
      <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 200 }} style={{ width: 400, height: "100%" }}>
        <Descriptions.Item label="Authorized Rate">{authRateData?.authorizedRate ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Additional Rate">{authRateData?.additionalRate ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Authorized Rate Units">{authRateData?.authorizedRateUnits ?? "N/A"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AuthorizedRatesTab;
