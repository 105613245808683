import StatusTag from "@/components/StatusTag/StatusTag";
import useDWRGetWaterRightByPdivQuery from "@/queries/useDWRGetWaterRightByPdivQuery";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Descriptions, Divider, Modal, Spin, Tabs } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import AquiferTab from "../AquiferTab/AquiferTab";
import AuthorizedAcresTab from "../AuthorizedAcresTab/AuthorizedAcresTab";
import AuthorizedQuantitiesTab from "../AuthorizedQuantitiesTab/AuthorizedQuantitiesTab";
import AuthorizedRatesTab from "../AuthorizedRatesTab/AuthorizedRatesTab";
import PlaceOfUseTab from "../PlaceOfUseTab/PlaceOfUseTab";
import WaterUseDataTab from "../WaterUseDataTab/WaterUseDataTab";
import LookupLabel from "@/components/LookupLabel/LookupLabel";
import DatesTab from "../DatesTab/DatesTab";

interface DWRWaterRightInfoModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: (data: any) => void;
  pdiv: number;
  index: number;
}

const DWRWaterRightInfoModal: FC<DWRWaterRightInfoModalProps> = (props) => {
  const { open, onCancel, onSuccess, pdiv, index } = props;

  const [disabled, setDisabled] = useState(true);
  const [waterRightData, setWaterRightData] = useState<any>(undefined);

  const draggleRef = useRef<HTMLDivElement>(null);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const { dwrWaterRightInfoByPdiv, invalidateDWRWaterRightInfoByPdiv, isFetched, isLoading } = useDWRGetWaterRightByPdivQuery({ pdiv });

  useEffect(() => {
    if (dwrWaterRightInfoByPdiv && dwrWaterRightInfoByPdiv?.length > 0) {
      setWaterRightData(dwrWaterRightInfoByPdiv[0]);
    }
    // eslint-disable-next-line
  }, [dwrWaterRightInfoByPdiv]);

  const handleSubmit = () => {
    onSuccess && onSuccess({ index: index });
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const renderTabs = () => {
    let tabs = [
      {
        label: "Authorized Quantity",
        key: "authorizedQuantity",
        children: <AuthorizedQuantitiesTab waterRightId={waterRightData?.waterRightId} pdiv={waterRightData?.pdivId} />,
      },
      {
        label: "Authorized Rate",
        key: "authorizedRate",
        children: <AuthorizedRatesTab waterRightId={waterRightData?.waterRightId} pdiv={waterRightData?.pdivId} />,
      },
      {
        label: "Authorized Acres",
        key: "authorizedAcres",
        children: <AuthorizedAcresTab waterRightId={waterRightData?.waterRightId} />,
      },
      {
        label: "Place Of Use Data",
        key: "placeOfUseData",
        children: <PlaceOfUseTab waterRightId={waterRightData?.waterRightId} pdiv={waterRightData?.pdivId} />,
      },
      {
        label: "Water Use Data",
        key: "waterUseData",
        children: <WaterUseDataTab pdiv={waterRightData?.pdivId} />,
      },
      {
        label: "Aquifer Data",
        key: "aquiferData",
        children: <AquiferTab pdiv={waterRightData?.pdiv} />,
      },
      {
        label: "Dates",
        key: "dates",
        children: <DatesTab waterRightData={waterRightData} />,
      },
    ];

    return tabs;
  };

  return (
    <Modal
      styles={{ body: { maxHeight: 750 } }}
      width={1200}
      open={open}
      title={
        <div
          style={{ width: "100%", cursor: "move" }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          Water Right / File Number {waterRightData?.fileNumber}
          <Divider style={{ padding: 0, marginTop: 5 }} />
        </div>
      }
      okText="Select"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        disabled: isLoading,
        loading: isLoading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        disabled: isLoading,
        loading: isLoading,
      }}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Descriptions bordered size="small">
            <Descriptions.Item label="Active">
              <StatusTag status={waterRightData?.active} />
            </Descriptions.Item>
            <Descriptions.Item label="PDIV Active">
              <StatusTag status={waterRightData?.pointOfDiversionActive} />
            </Descriptions.Item>
            <Descriptions.Item label="PDIV">{waterRightData?.pdivId}</Descriptions.Item>
            <Descriptions.Item label="CIN">{waterRightData?.cin}</Descriptions.Item>
            <Descriptions.Item label="Legal">
              Section: {waterRightData?.section ?? "-"} <br />
              Township: {waterRightData?.township ?? "-"} {waterRightData?.townshipDirection} <br />
              Range: {waterRightData?.range ?? "-"} {waterRightData?.rangeDirection}
            </Descriptions.Item>
            <Descriptions.Item label="Permit Type">
              <LookupLabel lookupType={"permitType"} dontShowCode value={waterRightData?.internalPermitType} />
            </Descriptions.Item>
            <Descriptions.Item label="GMD">{waterRightData?.gmd ?? "N/A"}</Descriptions.Item>
            <Descriptions.Item label="Water Right Qualifier">{waterRightData?.waterRightQualifier}</Descriptions.Item>
            <Descriptions.Item label="County Code">{waterRightData?.countyCode}</Descriptions.Item>
          </Descriptions>
          <br />
          <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} items={renderTabs()} />
        </>
      )}
    </Modal>
  );
};

export default DWRWaterRightInfoModal;
