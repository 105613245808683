import StatusTag from "@/components/StatusTag/StatusTag";
import useDWRGetWaterRightByFileNumberQuery from "@/queries/useDWRGetWaterRightByFileNumberQuery";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Card, Empty, Form, Input, message, Modal, Typography } from "antd";
import { FC, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import DWRImportSummaryModal from "../DWRImportSummaryModal/DWRImportSummaryModal";
import DWRWaterRightInfoModal from "../DWRWaterRightInfoModal/DWRWaterRightInfoModal";

import "./DWRSearchWaterRightModal.scss";
interface DWRSearchWaterRightModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: (data: any) => void;
}

const DWRSearchWaterRightModal: FC<DWRSearchWaterRightModalProps> = (props) => {
  const { open, onCancel, onSuccess } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const draggleRef = useRef<HTMLDivElement>(null);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const [waterRightInfoModalState, setWaterRightInfoModalState] = useState<any>({
    open: false,
    pdiv: undefined,
    index: undefined,
  });

  const [summaryModalState, setSummaryModalState] = useState<any>({
    open: false,
  });

  const { dwrWaterRightInfoByFileNumber, dwrWaterRightInfoByFileNumberFetched, invalidateDWRWaterRightInfoByFileNumber, isLoading } = useDWRGetWaterRightByFileNumberQuery({
    fileNumber: searchString ? searchString : undefined,
  });

  const handleSubmit = () => {
    if (selectedIndices.length > 0) {
      setSummaryModalState({ open: true, waterRights: dwrWaterRightInfoByFileNumber?.filter((wr: any, index: any) => selectedIndices.includes(index)) });
      // onCancel();
    } else {
      message.error("Please select at least 1 water right to continue");
    }
  };

  const handleDwrModalStateCancel = () => {
    setWaterRightInfoModalState({
      pdiv: undefined,
      open: false,
      index: undefined,
    });
  };

  const handleDwrModalStateSuccess = (data: any) => {
    if (!selectedIndices.includes(data.index)) {
      let indices = [...selectedIndices];
      indices.push(data.index);
      setSelectedIndices(indices);
    }

    handleDwrModalStateCancel();

    // message.success(`Successfully selected water right for import`);

    // TODO: Handle auto populate
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleClickCard = (waterRightData: any, index: number) => {
    setWaterRightInfoModalState({ open: true, pdiv: waterRightData?.pdivId, index: index });
  };

  const handleSummaryModalStateCancel = () => {
    setSummaryModalState({ open: false, waterRights: [] });
  };

  const handleSummaryModalStateSuccess = () => {
    handleSummaryModalStateCancel();
  };

  return (
    <>
      <Modal
        styles={{ body: { maxHeight: 750 } }}
        width={1000}
        open={open}
        title={
          <div
            style={{ width: "100%", cursor: "move" }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            Import Water Right
          </div>
        }
        okText="Continue"
        cancelText="Cancel"
        onOk={handleSubmit}
        onCancel={onCancel}
        maskClosable={false}
        cancelButtonProps={{
          icon: <CloseOutlined />,
          loading: loading,
        }}
        okButtonProps={{
          icon: <ThunderboltOutlined />,
          loading: loading,
        }}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form form={form}>
          <Form.Item name="fileNumber">
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Input.Search
                disabled={isLoading}
                placeholder="Search water rights"
                onSearch={(val) => {
                  setSearchString(val);
                  setSelectedIndices([]);
                }}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                allowClear
              />
            </div>
          </Form.Item>
        </Form>
        <Card bordered={false} className="removeBoxShadow" loading={isLoading}>
          {!isLoading && dwrWaterRightInfoByFileNumber?.length > 0 ? (
            dwrWaterRightInfoByFileNumber.map((waterRight: any, index: number) => (
              <Card.Grid onClick={() => handleClickCard(waterRight, index)} className={selectedIndices.includes(index) ? "selected" : ""}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    opacity: 1,
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      paddingBottom: 10,
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {waterRight?.fileNumber}
                  </Typography.Title>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div>Legal:</div>
                      <div style={{ marginLeft: "10px" }}>
                        Section: {waterRight?.section ?? "-"}
                        <div style={{ marginLeft: 0 }}>
                          <div>
                            Township: {waterRight?.township ?? "-"} {waterRight?.townshipDirection}
                          </div>
                          <div>
                            Range: {waterRight?.range ?? "-"} {waterRight?.rangeDirection}
                          </div>
                        </div>
                      </div>
                    </div>
                  </pre>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>CIN: {waterRight?.cin}</pre>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>
                    PDIV Status: <StatusTag status={waterRight?.pointOfDiversionActive} />
                  </pre>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>PDIV: {waterRight?.pdivId}</pre>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>Latitude: {waterRight?.latitude}</pre>
                  <pre style={{ margin: 0, fontFamily: "inherit" }}>Longitude: {waterRight?.longitude}</pre>
                </div>
              </Card.Grid>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Water Rights Found" />
          )}
        </Card>
        {waterRightInfoModalState.open && (
          <DWRWaterRightInfoModal
            open={waterRightInfoModalState.open}
            onCancel={handleDwrModalStateCancel}
            onSuccess={handleDwrModalStateSuccess}
            pdiv={waterRightInfoModalState.pdiv}
            index={waterRightInfoModalState.index}
          />
        )}
        {summaryModalState.open && (
          <DWRImportSummaryModal open={summaryModalState.open} waterRights={summaryModalState.waterRights} onCancel={handleSummaryModalStateCancel} onSuccess={handleSummaryModalStateSuccess} />
        )}
      </Modal>
    </>
  );
};

export default DWRSearchWaterRightModal;
