import useDWRGetAquiferByPdiv from "@/queries/useDWRGetAquiferByPdiv";
import { Card, Descriptions } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  pdiv: number;
}

const AquiferTab: FC<Props> = (props) => {
  const { pdiv } = props;

  const [aquifer, setAquifer] = useState<any>(undefined);

  const { dwrAquiferByPdiv: aquiferData, invalidateDWRWAquiferByPdiv, isFetched, isLoading } = useDWRGetAquiferByPdiv({ pdiv: 560 });

  useEffect(() => {
    if (aquiferData && aquiferData.length > 0) {
      const aquiferForWaterRight = aquiferData?.find((wrData: any) => wrData?.pdivId === pdiv);
      setAquifer(aquiferForWaterRight);
    }
    // eslint-disable-next-line
  }, [aquiferData]);

  return (
    <Card loading={isLoading}>
      <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 200 }} style={{ width: 400, height: "100%" }}>
        <Descriptions.Item label="Aquifer Code">{aquifer?.aquiferCode ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Aquifer Name">{aquifer?.aquiferName ?? "N/A"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AquiferTab;
