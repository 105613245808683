import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useWellUsageReportQuery from "@/queries/useWellUsageReportQuery";
import { convertUnits } from "@/services/utils";
import { Alert, Card, ConfigProvider, Descriptions, Image, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { forEach, orderBy } from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";

// import "./GeneratedEndOfYearSummaryReport.scss";

interface Props {
  reportConfig: {
    selectedWellNames: string;
    wellIds: string[];
    selectedWaterRightFileNumbers: string;
    waterRightIds: string[];
    permitTypes: string[];
    priorityTypes: string[];
    permitTypeNames: string;
    priorityTypeNames: string;
    generatedDate: string;
    year: number;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedGPMSummaryReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { companyConfigs } = useComapnyConfigLookup();

  const {
    wellUsageReport: reportData,
    isFetching,
    hasNextPage,
  } = useWellUsageReportQuery({
    permitTypes: reportConfig?.permitTypes,
    priorityTypes: reportConfig?.priorityTypes,
    waterRightIds: reportConfig?.waterRightIds,
    generatedDate: reportConfig?.generatedDate,
  });

  const generateReadings = (item: any, totalsForTheYear: any, beginningMeterReading: any) => {
    const companyConfig = companyConfigs?.find((company: any) => company?.id === item.companyId);
    let readingsForTheYear = item.readings?.find((x: any) => x.year === reportConfig.year)?.readings ?? [];

    readingsForTheYear = orderBy(readingsForTheYear, "date", "asc");

    // let meterConfigHistory: any[] = [];

    let previousYearReading = beginningMeterReading ?? undefined;
    if (previousYearReading !== undefined && previousYearReading?.id !== readingsForTheYear?.filter((x: any) => x.type !== "Not Collected")?.[0]?.id) {
      previousYearReading.consumedSinceLastReading = 0;
      previousYearReading.extraNote = "This is the final meter reading for the previous year";
      readingsForTheYear.unshift(previousYearReading);
    }

    let lastReading = undefined;
    for (let index = 0; index < readingsForTheYear.length; index++) {
      const reading = readingsForTheYear[index];

      let gpm = undefined;

      // if
      if (reading.type !== "Not Collected") {
        if (lastReading) {
          // calculate gpm between readings
          const readingDate = dayjs(reading.date);
          const prevReadingDate = dayjs(lastReading.date);

          const diffInMinutes = readingDate.diff(prevReadingDate, "minute", true);

          gpm = diffInMinutes === 0 ? 0 : reading.consumedSinceLastReading / diffInMinutes;
        }
        lastReading = reading;
      }
      reading.gpm = gpm;
    }

    /**
     * Places Of Use Logic
     */
    const currentYearReadings = item.readings?.find((reading: any) => reading.year === reportConfig.year);
    const newReadings = currentYearReadings?.readings?.map((reading: any) => {
      return { ...reading, placesOfUse: reading?.placesOfUse?.join(", ") };
    });

    const columns: ColumnType<any>[] = [
      {
        title: "#",
        width: 25,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 170,
        render: (val: string) => dayjs(val).format(constants.dateTimeFormatWithSeconds),
      },
      // {
      //   title: "Reader",
      //   dataIndex: "reader",
      //   key: "reader",
      // },
      // {
      //   title: "New Meter",
      //   dataIndex: "newMeter",
      //   key: "newMeter",
      //   render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : val),
      // },
      {
        title: "Reading",
        dataIndex: "reading",
        key: "reading",
        render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : val ?? "-"),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      // {
      //   title: "Units",
      //   dataIndex: "meterUnits",
      //   key: "meterUnits",
      //   width: 250,
      //   render: (val: string, record: any) => (record.type === "Not Collected" ? "-" : <LookupLabel lookupType="meterUnits" value={val} />),
      // },
      // {
      //   title: "Places of Use",
      //   dataIndex: "placesOfUse",
      //   key: "placesOfUse",
      //   render: (val: any[], record: any) => (record?.placesOfUse ? record?.placesOfUse?.join(", ") : "-"),
      // },
    ];

    if (companyConfig?.settings?.metric !== "gallons")
      columns.push({
        title: <LookupLabel lookupType="meterUnits" value={companyConfig?.settings?.metric} />,
        dataIndex: "consumedSinceLastReading",
        key: "consumedSinceLastReadingInCompanyMetric",
        align: "right",
        render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter value={val} fromUnits="gallons" toUnits={companyConfig?.settings?.metric} />),
      });

    if (!item.irrigationAcresNotApplicable && !item.quantityNotApplicable) {
      columns.push({
        title: "Inches / Acre",
        dataIndex: "consumedSinceLastReading",
        key: "incesacre",
        align: "right",
        render: (val: number, record: any) =>
          record.type === "Not Collected" ? "-" : <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={val} devideBy={item.authorizedIrrigatedAcres} />,
      });
    }

    columns.push({
      title: "Gallons",
      dataIndex: "consumedSinceLastReading",
      key: "gallons",
      align: "right",
      render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} />),
    });

    columns.push({
      title: "GPM",
      dataIndex: "gpm",
      key: "gpm",
      align: "right",
      render: (val: number, record: any) => (record.type === "Not Collected" ? "-" : <UnitsConverter overrideDecimalPlaces={2} fromUnits="gallons" toUnits="gallons" value={val} />),
    });

    return (
      <Card className="removeBoxShadow removeMargin media" bordered={false}>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Table
            style={{ width: "100%" }}
            rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
            rowKey={(row: any) => row.id}
            size="small"
            columns={columns}
            dataSource={readingsForTheYear}
            pagination={false}
            expandable={{
              showExpandColumn: false,
              expandedRowKeys: readingsForTheYear.filter((reading: any) => reading.notes !== null || reading.extraNote !== undefined).map((reading: any) => reading.id),
              expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
            }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row className="moveDown">
                    <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      <b>Total:</b>
                    </Table.Summary.Cell>
                    {companyConfig.settings?.metric !== "gallons" && (
                      <Table.Summary.Cell index={4} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits={companyConfig.settings?.metric} value={totalsForTheYear?.totalConsumed ?? 0} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    {!item.irrigationAcresNotApplicable && (
                      <Table.Summary.Cell index={5} align="right">
                        <b>
                          <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={totalsForTheYear?.totalConsumed ?? 0} devideBy={item.authorizedIrrigatedAcres} />
                        </b>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={6} align="right">
                      <b>
                        <UnitsConverter fromUnits="gallons" toUnits="gallons" value={totalsForTheYear?.totalConsumed ?? 0} />
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </ConfigProvider>
      </Card>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedLastReadingReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`GPM Summary Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={isFetching || hasNextPage}
        >
          {!(isFetching || hasNextPage) && reportData.length === 0 && <Alert type="warning" description="The selected water rights does not have any wells" />}
          {!(isFetching || hasNextPage) && reportData.length > 0 && (
            <>
              {reportData.map((item: any, index, array) => {
                const finalItem = { ...item };

                const totalsForTheYear = finalItem.totals?.find((x: any) => x.year === reportConfig.year);

                let totalPenalty = 0;
                let totalAdjustment = 0;
                let totalTransferred = 0;

                forEach(finalItem.waterRightAdjustments ?? [], (adjustment: any) => {
                  if (adjustment.year === reportConfig.year) {
                    forEach(adjustment.adjustments ?? [], (adjustmentForYear: any) => {
                      if (adjustmentForYear.completed) {
                        if (adjustmentForYear.type === "penalty") totalPenalty += adjustmentForYear.quantityInGallons;
                        else if (adjustmentForYear.type === "transfer") totalTransferred += adjustmentForYear.quantityInGallons;
                        else if (adjustmentForYear.type === "adjustment") {
                          // Do not switch the calculation around, the values are returned as negative from the API
                          if (adjustmentForYear.adjustmentType === "subtraction") totalAdjustment -= adjustmentForYear.quantityInGallons;
                          else if (adjustmentForYear.adjustmentType === "addition") totalAdjustment -= adjustmentForYear.quantityInGallons;
                        }
                      }
                    });
                  }
                });

                const gallonsPossibleWithoutPenalty = convertUnits(
                  finalItem.usageQuantity && finalItem.usageQuantityUnits ? finalItem.usageQuantity : finalItem.authorizedQuantity,
                  finalItem.usageQuantity && finalItem.usageQuantityUnits ? finalItem.usageQuantityUnits : finalItem.authorizedQuantityUnits,
                  "gallons"
                );
                const gallonsPossibleAfterPenalty = gallonsPossibleWithoutPenalty - totalPenalty;
                const gallonsPossibleAfterMds = Math.floor(gallonsPossibleAfterPenalty * (finalItem.subjectToMds ? 0.32 : 1));
                const gallonsPossibleAfterTransfers = gallonsPossibleAfterMds - totalTransferred;

                let totalSharedConsumed = 0;
                forEach(finalItem.sharedWith ?? [], (sharedWith: any) => {
                  const sharedWithTotalsForTheYear = sharedWith.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                  totalSharedConsumed += sharedWithTotalsForTheYear;
                });

                let totalReduced = 0;
                forEach(finalItem.reductionWells ?? [], (reductionWell: any) => {
                  const reducedTotalsForTheYear = reductionWell.readings?.find((x: any) => x.year === reportConfig.year)?.totalConsumed ?? 0;
                  totalReduced += reducedTotalsForTheYear;
                });

                const totalGallonsUsedBeforeShared = (totalsForTheYear?.totalConsumed ?? 0) - totalReduced;
                const totalGallonsUsedBeforeAdjustment = (totalsForTheYear?.totalConsumed ?? 0) + totalSharedConsumed - totalReduced;
                const totalGallonsUsedAfterAdjustment = totalGallonsUsedBeforeAdjustment + totalAdjustment;
                const gallonsLeft = gallonsPossibleAfterTransfers - totalGallonsUsedAfterAdjustment;

                const waterRightTotal = totalGallonsUsedAfterAdjustment + totalSharedConsumed;
                const waterRightTotalWithAdjustments = waterRightTotal;
                const leftBefore = gallonsPossibleAfterMds - waterRightTotal;
                const left = gallonsPossibleAfterMds - waterRightTotal;

                let lastMeterReading = null;
                const lastReadings = orderBy(finalItem.readings?.find((x: any) => x.year === reportConfig.year)?.readings?.filter((x: any) => x.type !== "Not Collected") ?? [], "date", "desc");
                if (lastReadings?.length > 0) lastMeterReading = lastReadings[0];

                let beginningMeterReading = null;
                let reportYear = reportConfig.year;
                while (beginningMeterReading === null) {
                  const tempReportYear = reportYear;
                  const previousYearReading = orderBy(
                    finalItem.readings?.find((x: any) => x.year === tempReportYear - 1)?.readings?.filter((x: any) => x.type !== "Not Collected") ?? [],
                    "date",
                    "desc"
                  );
                  if (previousYearReading?.length > 0) {
                    beginningMeterReading = previousYearReading[0];
                    break;
                  }
                  //Hardcode hard stop at 2010
                  else if (reportYear > 2010) {
                    reportYear--;
                  } else {
                    break;
                  }
                }
                if (beginningMeterReading === null && lastReadings?.length > 0) beginningMeterReading = lastReadings[lastReadings.length - 1];

                return (
                  <>
                    <Card
                      key={finalItem.wellId}
                      title={
                        <Typography
                          style={{
                            whiteSpace: "pre-wrap",
                            fontSize: 16,
                            paddingBottom: 0,
                            paddingTop: 10,
                          }}
                        >
                          {finalItem.name}
                        </Typography>
                      }
                      bodyStyle={{ padding: 10 }}
                      extra={!selectedCompanyId && <CompanyLabel companyId={finalItem.companyId} />}
                    >
                      {generateReadings(finalItem, totalsForTheYear, beginningMeterReading)}
                    </Card>
                    {index < array.length - 1 && <div className="page-break" />}
                  </>
                );
              })}
            </>
          )}
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Wells">{reportConfig?.selectedWellNames === "" ? "-" : reportConfig.selectedWellNames}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig?.selectedWaterRightFileNumbers === "" ? "-" : reportConfig?.selectedWaterRightFileNumbers}</Descriptions.Item>
                <Descriptions.Item label="Permit Types">{reportConfig?.permitTypeNames === "" ? "-" : reportConfig?.permitTypes}</Descriptions.Item>
                <Descriptions.Item label="Priority Types">{reportConfig?.priorityTypeNames === "" ? "-" : reportConfig?.priorityTypes}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedGPMSummaryReport;
