import { FileImageOutlined, MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Form, Input, Modal, Typography, Upload, message } from "antd";
import { listBulkNotificationAttachment, sendSupportEmail } from "@/apis/communication.api";
import { constants } from "@/configs";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveSupportQueryData } from "@/stores/communication.store";

interface Props {
  mobile?: boolean;
  onSuccess?: (ref: any) => void;
  open?: boolean;
  onCancel?: (data: any) => void;
}

const SupportEmailModal: FC<Props> = (props) => {
  const { onSuccess, open, onCancel, mobile } = props;

  const dispatch = useAppDispatch();

  const { supportQueryDataState } = useSelector((state: any) => state.communication);

  const [form] = Form.useForm();

  const { profile } = useSelector((state: any) => state.user);
  const [fileList, setFileList] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [loadingAttachments, setLoadingAttachments] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phoneNumber: profile?.phoneNumber,
        userId: profile?.userId,
        body: undefined,
      });
    }
    if (supportQueryDataState) {
      if (supportQueryDataState?.attachments?.length > 0 && fileList.length === 0) {
        // Set file list from previously saved query
        getNotificationAttachments(supportQueryDataState?.attachments);
      }
      form.setFieldsValue(supportQueryDataState);
    }
  }, [profile, supportQueryDataState, fileList]);

  const getNotificationAttachments = async (attachments: any[]) => {
    setLoadingAttachments(true);

    const request = { attachmentIds: attachments };

    const response = await listBulkNotificationAttachment(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFileList(
          data.value.map((attachment: any) => {
            return {
              uid: attachment.id,
              name: attachment.fileName,
              status: "done",
              url: `${constants.baseApiUrl}/communication/support/attachment/${attachment.id}/download`,
              existingFile: true,
              type: attachment.mimeType,
            };
          })
        );
      }
    }

    setLoadingAttachments(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const submitQuery = {
          firstName: values.firstName,
          lastName: values.lastName,
          userEmail: values.email,
          attachments: fileList.map((file) => file?.response?.value) ?? null,
          subject: values.subject,
          body: `Support Request 
        
                    First Name: ${values.firstName}
                    Last Name: ${values.lastName}
                    Email: ${values.email}
                    Phone Number: ${values.phoneNumber}
                    
                    Message: ${values.body}
                    `,
          phoneNumber: values.phoneNumber ?? null,
        };

        handleSupportQuerySubmitData(submitQuery);
      })
      .catch((errors) => {
        message.error("Support query failed to send, please try again.");
        setLoading(false);
      });
  };

  const handleSupportQuerySubmitData = async (data: any) => {
    const response = await sendSupportEmail(data);

    setLoading(false);
    if (response.ok) {
      if (mobile) {
        //TODO
      } else {
        if (response.ok) {
          const data = await response.json();
          if (data.isSuccess) {
            onSuccess && onSuccess(data.value);
          } else {
            message.error("Support query failed to send, please try again.");
          }
        }
      }
    } else {
      message.error("Support query failed to send, please try again.");
    }
  };

  const renderContent = () => {
    return (
      <>
        <Form
          id="supportQueryModal"
          form={form}
          onValuesChange={() => {
            dispatch(
              saveSupportQueryData({
                ...form?.getFieldsValue(),
                userId: profile?.userId,
              })
            );
          }}
          layout="vertical"
        >
          <Form.Item name="firstName" label="First Name">
            <Input prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>

          <Form.Item name="lastName" label="Last Name">
            <Input prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid e-mail",
              },
              {
                required: true,
                message: "Please input your e-mail",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item name="phoneNumber" label="Phone Number">
            <Input prefix={<PhoneOutlined />} placeholder="Phone Number (Optional)" />
          </Form.Item>

          <Form.Item
            name="body"
            label="Support Query"
            rules={[
              {
                required: true,
                message: "Please enter an explanation for your query",
              },
            ]}
          >
            <Input.TextArea rows={6} placeholder="How can we assist you?" />
          </Form.Item>

          <Form.Item>
            <Upload.Dragger
              multiple={true}
              action={`${constants.baseApiUrl}/communication/bulknotification/attachment/upload`}
              listType="picture-card"
              fileList={fileList}
              onChange={(info: any) => {
                if (info.file.status === "uploading") {
                  setUploading(true);
                }
                if (info.file.status === "done") {
                  // dispatch(saveSupportQueryData({ ...supportQueryDataState, attachments: info.fileList ?? [] }));
                  dispatch(
                    saveSupportQueryData({
                      ...supportQueryDataState,
                      attachments: info.fileList.map((file: any) => file?.response?.value) ?? [],
                    })
                  );
                  setUploading(false);
                }

                if (info.file.status === "error") {
                  setUploading(false);
                }

                if (info.file.status === "removed") {
                  dispatch(
                    saveSupportQueryData({
                      ...supportQueryDataState,
                      attachments: info.fileList.map((file: any) => file?.response?.value) ?? [],
                    })
                  );
                }
                setFileList(info.fileList);
              }}
            >
              <p className="ant-upload-drag-icon">
                <FileImageOutlined />
              </p>
              <p className="ant-upload-text">Click or drag a file to this area to upload</p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item>
            <Button type="primary" style={{ width: "100%" }} onClick={handleSubmit} disabled={loading || uploading} loading={loading || uploading}>
              Submit Query
            </Button>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} onClick={onCancel} disabled={loading || uploading} loading={loading || uploading}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <Drawer title="Submit a Support Query" open={open} placement="right" width={500} onClose={onCancel} bodyStyle={{ paddingTop: 5 }} closable={false} maskClosable={false}>
      <p>Please complete the fields below and submit.</p>
      {renderContent()}
    </Drawer>
  );
};

export default SupportEmailModal;
