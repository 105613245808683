import { getWaterRightNotes } from "@/apis/waterright.api";
import { getWellNotes, getWellReadingNotes } from "@/apis/well.api";
import PrintPage from "@/components/PrintPage/PrintPage";
import { constants } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { Alert, Card, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedNotesReport.scss";
import CompanyLabel from "@/components/Lookups/CompanyLabel";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedNotesReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [wellIds, setWellIds] = useState<string[]>([]);
  const [waterRightIds, setWaterRightIds] = useState<string[]>([]);
  const [wellNotes, setWellNotes] = useState<any[]>([]);
  const [waterRightNotes, setWaterRightNotes] = useState<any[]>([]);
  const [readingNotes, setReadingNotes] = useState<any[]>([]);

  const [noteColumns, setNoteColumns] = useState<any[]>([]);
  const [readingNoteColumns, setReadingNoteColumns] = useState<any[]>([]);

  const [loadingWellNotes, setLoadingWellNotes] = useState<boolean>(false);
  const [loadingWaterRightNotes, setLoadingWaterRightNotes] = useState<boolean>(false);
  const [loadingWellReadingNotes, setLoadingWellReadingNotes] = useState<boolean>(false);

  const { wells } = useWellLookups(undefined, selectedCompanyId, reportConfig.wellActive ? reportConfig.wellActive : undefined);
  const { waterRights } = useWaterRightLookups(undefined, selectedCompanyId, reportConfig.waterRightActive ? reportConfig.waterRightActive : undefined);
  const { companyConfigs } = useComapnyConfigLookup();

  useEffect(() => {
    if (reportConfig.wells?.wells?.length > 0 || reportConfig.waterRights?.waterRights?.length > 0) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    const wellIds = reportConfig?.wells?.wells?.map((well: any) => well?.wellId);
    const waterRightIds = reportConfig?.waterRights?.waterRights?.map((waterRight: any) => waterRight?.waterRightId);

    if (wellIds?.length > 0 || waterRightIds?.length > 0) {
      const waterRightWellIds = wells?.filter((well: any) => waterRightIds?.includes(well?.waterRightId))?.map((well: any) => well?.id);
      const wellWaterRightIds = wells?.filter((well: any) => wellIds?.includes(well?.id))?.map((well: any) => well?.waterRightId);

      const finalWellIds = new Set([...wellIds, ...waterRightWellIds]);
      const finalWaterRightIds = new Set([...waterRightIds, ...wellWaterRightIds]);

      setWellIds(Array.from(finalWellIds.values()));
      setWaterRightIds(Array.from(finalWaterRightIds.values()));
    } else {
      setWellIds(wells?.map((well: any) => well?.id));
      setWaterRightIds(waterRights?.map((waterRights: any) => waterRights?.id));
    }
  }, [reportConfig]);

  useEffect(() => {
    if (reportConfig && wellIds && waterRightIds) {
      // Water Right Id or Well Id
      refreshWellNotes();
      refreshWaterRightNotes();
      refreshWellReadingNotes();
    }
    // eslint-disable-next-line
  }, [reportConfig, wellIds, waterRightIds]);

  useEffect(() => {
    calculateNoteColumns();
    // eslint-disable-next-line
  }, [wells, waterRights, wellNotes, waterRightNotes]);

  useEffect(() => {
    calculateNoteReadingColumns();
    // eslint-disable-next-line
  }, [wells, waterRights, readingNoteColumns]);

  const refreshWellNotes = async () => {
    setLoadingWellNotes(true);

    const request = {
      wellIds: wellIds,
    };

    const response = await getWellNotes(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWellNotes(data.value);
      }
    }
    setLoadingWellNotes(false);
  };

  const refreshWellReadingNotes = async () => {
    setLoadingWellReadingNotes(true);

    const request = {
      wellIds: wellIds,
    };

    const response = await getWellReadingNotes(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setReadingNotes(data.value);
      }
    }
    setLoadingWellReadingNotes(false);
  };

  const refreshWaterRightNotes = async () => {
    setLoadingWaterRightNotes(true);

    const request = {
      waterRightIds: waterRightIds,
    };

    const response = await getWaterRightNotes(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightNotes(data.value);
      }
    }
    setLoadingWaterRightNotes(false);
  };

  const calculateNoteColumns = () => {
    let tempColumns: any[] = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Created At",
        key: "createdAt",
        dataIndex: "createdAt",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Created By",
        key: "createdByUserName",
        dataIndex: "createdByUserName",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Note",
        key: "note",
        dataIndex: "note",
        render: (val: any, record: any) => (
          <pre
            style={{
              width: "100%",
              margin: 0,
              fontFamily: "inherit",
              display: "block",
              wordBreak: "break-all",
              overflowWrap: "anywhere",
            }}
          >
            {val}
          </pre>
        ),
      },
    ];

    setNoteColumns(tempColumns);
  };

  const calculateNoteReadingColumns = () => {
    let tempColumns: any[] = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Created At",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Note",
        key: "notes",
        dataIndex: "notes",
        render: (val: any, record: any) => (
          <pre
            style={{
              width: "100%",
              margin: 0,
              fontFamily: "inherit",
              display: "block",
              wordBreak: "break-all",
              overflowWrap: "anywhere",
            }}
          >
            {val}
          </pre>
        ),
      },
    ];

    setReadingNoteColumns(tempColumns);
  };

  const customiseRenderEmpty = (message: string) => {
    return (
      <>
        <Alert message={`${message}`} type="warning" />
      </>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedNoteReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Notes Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={generatingReport}
        >
          <Card title="Well Notes">
            {reportConfig && wellIds?.length > 0 ? (
              wellIds.map((wellId: any) => {
                const companyConfig = companyConfigs?.find((company: any) => company?.id === wells?.find((well: any) => well?.id === wellId)?.companyId);

                return (
                  <>
                    <Card
                      title={
                        <>
                          <div>{wells?.find((well: any) => well?.id === wellId)?.name}</div>
                        </>
                      }
                      extra={!selectedCompanyId && <CompanyLabel companyId={companyConfig?.id} />}
                    >
                      {wellNotes.filter((well) => well.wellId === wellId)?.length > 0 ? (
                        <>
                          <Title style={{ marginTop: 5 }} level={5}>
                            General Well Notes
                          </Title>
                          <br />
                          <Table loading={loadingWellNotes} columns={noteColumns} dataSource={wellNotes.filter((well) => well.wellId === wellId) ?? []} size="small" pagination={false} />
                        </>
                      ) : (
                        <Alert message="No notes found for well." type="warning" />
                      )}

                      {readingNotes.filter((well) => well.wellId === wellId)?.length > 0 ? (
                        <>
                          <Title level={5}>Well Readings Notes</Title>
                          <br />
                          <Table
                            loading={loadingWellReadingNotes}
                            columns={readingNoteColumns}
                            dataSource={readingNotes.filter((well) => well.wellId === wellId) ?? []}
                            size="small"
                            pagination={false}
                          />
                        </>
                      ) : (
                        <>
                          <br />
                          <Alert message="No reading notes found for well." type="warning" />
                        </>
                      )}
                    </Card>
                    <div className="page-break" />
                  </>
                );
              })
            ) : (
              <Alert message="No wells in config." type="warning" />
            )}
          </Card>

          <Card title="Water Right Notes">
            {reportConfig && waterRightIds.length > 0 ? (
              waterRightIds.map((waterRightId: any) => {
                const companyConfig = companyConfigs?.find((company: any) => company?.id === waterRights?.find((waterRight: any) => waterRight?.id === waterRightId)?.companyId)?.name;

                return (
                  <>
                    <Card
                      title={
                        <>
                          <div>{waterRights?.find((waterRight: any) => waterRight?.id === waterRightId)?.fileNumber}</div>
                          {!selectedCompanyId && <div style={{ fontSize: 11 }}>({companyConfig})</div>}
                        </>
                      }
                    >
                      {waterRightNotes.filter((waterRight) => waterRight.waterRightId === waterRightId)?.length > 0 ? (
                        <Table
                          loading={loadingWaterRightNotes}
                          columns={noteColumns}
                          dataSource={waterRightNotes.filter((waterRight) => waterRight.waterRightId === waterRightId) ?? []}
                          size="small"
                          pagination={false}
                        />
                      ) : (
                        <Alert message="No water right notes found." type="warning" />
                      )}
                    </Card>
                    <div className="page-break" />
                  </>
                );
              })
            ) : (
              <Alert message="No water rights in config." type="warning" />
            )}
          </Card>

          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Water Rights">
                  {reportConfig?.waterRights?.waterRights?.length > 0 ? reportConfig.waterRights.waterRights.map((waterRight: any) => waterRight.fileNumber).join(", ") : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Wells">{reportConfig?.wells?.wells?.length > 0 ? reportConfig.wells.wells.map((well: any) => well.name).join(", ") : "-"}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedNotesReport;
