import LookupLabel from "@/components/LookupLabel/LookupLabel";
import StatusTag from "@/components/StatusTag/StatusTag";
import useDWRWaterRightAuthorizedQuantitySummaryQuery from "@/queries/useDWRWaterRightAuthorizedQuantitySummaryQuery";
import useDWRWaterRightSummaryQuery from "@/queries/useDWRWaterRightSummaryQuery";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Card, Descriptions, Divider, Modal, Typography } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  open: boolean;
  waterRights: any[];
  onCancel: () => void;
  onSuccess: (data: any) => void;
}

const DWRImportSummaryModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess, waterRights } = props;

  const [loading, setLoading] = useState<boolean>(false);

  // const { waterRightSummary, isLoading, invalidateWaterRightSummary } = useDWRWaterRightSummaryQuery(waterRights?.map((waterRightData) => waterRightData?.pdivId));
  // const { authorizedQuantitiesSummary } = useDWRWaterRightAuthorizedQuantitySummaryQuery(waterRights?.map((waterRight) => { return { waterRightId: waterRight?.waterRightId, pdivId: waterRight?.pdivId } }))

  // useEffect(() => {
  //   console.log("authorized quantities summary => ", authorizedQuantitiesSummary);
  // }, [authorizedQuantitiesSummary])

  const handleSubmit = () => {
    onSuccess && onSuccess("");
  };

  return (
    <Modal
      styles={{ body: { maxHeight: 750, overflowY: "auto" } }}
      width={1000}
      open={open}
      title={"Import Summary"}
      okText="Import"
      cancelText="Cancel"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        loading: loading,
        disabled: true,
      }}
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={true}
    >
      {waterRights?.map((waterRight: any) => (
        <Card
          title={
            <>
              {waterRight?.fileNumber}
              <StatusTag style={{ marginLeft: 5 }} status={waterRight?.active} />
            </>
          }
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="PDIV">{waterRight.pdivId}</Descriptions.Item>
            <Descriptions.Item label="CIN">{waterRight?.cin}</Descriptions.Item>
            <Descriptions.Item label="Permit Type">
              <LookupLabel lookupType={"permitType"} dontShowCode value={waterRight?.internalPermitType} />
            </Descriptions.Item>
            <Descriptions.Item label="PDIV Status">
              <StatusTag status={waterRight?.pointOfDiversionActive} />
            </Descriptions.Item>
            <Descriptions.Item label="Latitude">{waterRight.latitude}</Descriptions.Item>
            <Descriptions.Item label="Longitude">{waterRight?.longitude}</Descriptions.Item>
            <Descriptions.Item label="Legal">
              Section: {waterRight?.section ?? "-"} <br />
              Township: {waterRight?.township ?? "-"} {waterRight?.townshipDirection} <br />
              Range: {waterRight?.range ?? "-"} {waterRight?.rangeDirection}
            </Descriptions.Item>
          </Descriptions>
          {/* <Divider style={{ marginBottom: 5 }} orientation="left"></Divider>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Authorized Quantities
          </Typography.Title>
          <Divider style={{ marginBottom: 5 }} orientation="left"></Divider>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Water Use Data
          </Typography.Title> */}
        </Card>
      ))}
    </Modal>
  );
};

export default DWRImportSummaryModal;
