import useDWRGetAuthorizedAcresByWaterRightIdQuery from "@/queries/useDWRGetAuthorizedAcresByWaterRightIdQuery";
import { Card, Empty, Table } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  waterRightId: number;
}

const AuthorizedAcresTab: FC<Props> = (props) => {
  const { waterRightId } = props;

  const [columns, setColumns] = useState<any[]>([]);

  const { dwrAuthorizedAcresByWaterRightId: authorizedAcres, invalidateDWRWAuthorizedAcresByWaterRightId, isFetched, isLoading } = useDWRGetAuthorizedAcresByWaterRightIdQuery({ waterRightId });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [authorizedAcres]);

  const calculateColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Quarter Section Code",
        key: "quarterSectionCode",
        dataIndex: "quarterSectionCode",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Quarter Quarter Section Code",
        key: "quarterQuarterSectionCode",
        dataIndex: "quarterQuarterSectionCode",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "Authorized Acres",
        key: "authorizedAcres",
        dataIndex: "authorizedAcres",
        align: "right",
        render: (val: any, record: any) => val ?? "N/A",
      },
      {
        title: "New Authorized Acres",
        key: "newAuthorizedAcres",
        dataIndex: "newAuthorizedAcres",
        align: "right",
        render: (val: any, record: any) => val ?? "N/A",
      },
    ];

    setColumns(tempColumns);
  };

  return (
    <Card loading={isLoading}>
      <Table
        style={{ height: 400 }}
        rowKey={(row: any) => row.pdivId}
        loading={isLoading}
        columns={columns}
        dataSource={authorizedAcres || []}
        size="small"
        pagination={false}
        className="customScrollBarStyle"
        scroll={{ y: 350 }}
        locale={{
          emptyText: <Empty description="No Authorized Acres Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
        summary={() => {
          const authAcres = authorizedAcres?.map((data: any) => data?.authorizedAcres);
          const authAcresTotal = authAcres?.reduce((total: number, current: number) => total + current, 0);

          const newAuthAcres = authorizedAcres?.map((data: any) => data?.newAuthorizedAcres);
          const newAuthAcresTotal = newAuthAcres?.reduce((total: number, current: number) => total + current, 0);

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                <b>Total</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {authAcresTotal}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {newAuthAcresTotal}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default AuthorizedAcresTab;
