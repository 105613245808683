import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Card, Modal, Typography } from "antd";
import { FC, useState } from "react";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSuccess: (data: any) => void;
}

const SelectWaterRightTypeModal: FC<Props> = (props) => {
  const { open, onCancel, onSuccess } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const handleSubmit = () => {
    setLoading(true);

    if (!selected) return;

    onSuccess && onSuccess(selected);

    setLoading(false);
  };

  const handleClickCard = (type: any, index: number) => {
    setSelected(type);
    setSelectedIndex(index);
  };

  return (
    <Modal
      closable={false}
      maskClosable={false}
      open={open}
      okText="Submit"
      cancelText="Cancel"
      onOk={handleSubmit}
      onCancel={onCancel}
      cancelButtonProps={{
        icon: <CloseOutlined />,
        loading: loading,
      }}
      okButtonProps={{
        icon: <ThunderboltOutlined />,
        loading: loading,
        disabled: !selected,
      }}
    >
      <Card loading={loading} title="Add Water Right" className="removeBoxShadow removeMargin">
        <div>
          <Typography.Paragraph>Please select your preferred method</Typography.Paragraph>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Card.Grid
            onClick={() => handleClickCard("dwr", 0)}
            className={selectedIndex === 0 ? "selected" : ""}
            style={{
              flex: 1,
              textAlign: "center",
              height: "100px",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: 1,
              }}
            >
              Import from DWR
            </div>
          </Card.Grid>
          <Card.Grid
            onClick={() => handleClickCard("manual", 1)}
            className={selectedIndex === 1 ? "selected" : ""}
            style={{
              flex: 1,
              textAlign: "center",
              height: "100px",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                opacity: 1,
              }}
            >
              Manual
            </div>
          </Card.Grid>
        </div>
      </Card>
    </Modal>
  );
};

export default SelectWaterRightTypeModal;
