import useDWRGetAuthorizedQuantitiesByWaterRightIdQuery from "@/queries/useDWRGetAuthorizedQuantitiesByWaterRightIdQuery";
import { Card, Descriptions } from "antd";
import { FC, useEffect, useState } from "react";

interface Props {
  waterRightId: number;
  pdiv: number;
}

const AuthorizedQuantitiesTab: FC<Props> = (props) => {
  const { waterRightId, pdiv } = props;

  const [authData, setAuthData] = useState<any>(undefined);

  const { dwrAuthorizedQuantitiesByWaterRightId, invalidateDWRWAuthorizedQuantitiesByWaterRightId, isFetched, isLoading } = useDWRGetAuthorizedQuantitiesByWaterRightIdQuery({ waterRightId });

  useEffect(() => {
    if (dwrAuthorizedQuantitiesByWaterRightId && dwrAuthorizedQuantitiesByWaterRightId.length > 0) {
      const authDataForWaterRight = dwrAuthorizedQuantitiesByWaterRightId?.find((wrData: any) => wrData?.pdivId === pdiv);
      setAuthData(authDataForWaterRight);
    }
    // eslint-disable-next-line
  }, [dwrAuthorizedQuantitiesByWaterRightId]);

  return (
    <Card loading={isLoading}>
      <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 200 }} style={{ width: 400, height: "100%" }}>
        <Descriptions.Item label="Authorized Quantity">{authData?.authorizedQuantity ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Additional Quantity">{authData?.additionalQuantity ?? "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Authorized Quantity Units">{authData?.authorizedQuantityUnits ?? "N/A"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default AuthorizedQuantitiesTab;
