import { getPlaceOfUseDataByWaterRightId } from "@/apis/dwr.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type DWRGetPlaceOfUseDataByWaterRightIdProps = {
  waterRightId: number;
};

export default function useDWRGetPlaceOfUseDataByWaterRightId(props: DWRGetPlaceOfUseDataByWaterRightIdProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched,
    data: dwrPlaceOfUseDataByWaterRightId,
    isLoading,
  } = useQuery({
    queryKey: [
      CacheKeys.dwr,
      CacheKeys.placeOfUseDataByWaterRightId,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPlaceOfUseDataByWaterRightId({
        waterRightId: props.waterRightId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch dwr place of use data by water right id");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 120 * 1000 * 1000, // 2 hours
  });

  const invalidateDWRWPlaceOfUseDataByWaterRightId = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        CacheKeys.dwr,
        CacheKeys.placeOfUseDataByWaterRightId,
        {
          waterRightId: props.waterRightId,
        },
      ],
    });
  }, [queryClient, props.waterRightId]);

  return {
    isFetched,
    dwrPlaceOfUseDataByWaterRightId,
    invalidateDWRWPlaceOfUseDataByWaterRightId,
    isLoading,
  };
}
